<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="add-parts-div">
      <img class="cursor-el" src="../../assets/add-circle.png" alt="添加部件" title="添加部件" @click="onAddParts"/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div>
          <div class="item-div">
            <div class="item-name-div">选择品牌：</div>
            <div class="item-value-div select-div">
              <my-select class="my-select" :option-data="[{id:0, name:'请选择品牌'}]" :load-more="true" :action="brandNameItems" :params="selectBrandNameParams" :change="onSelectBrandChanged" placeholder-txt="请选择品牌"/>
              <label>{{ machineryInfo.brandName }}</label>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">设备名称：</div>
            <div class="item-value-div">
              <validation-provider rules="required|minmax:1,20" v-slot="{ errors }" name="machineryName">
                <label><input v-model="machineryInfo.name" type="text"></label>
                <label class="err-tips">{{ errors[0] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">副标题：</div>
            <div class="item-value-div">
              <validation-provider rules="max:200" v-slot="{ errors }" name="subtitle">
                <label><input v-model="machineryInfo.subtitle" type="text"></label>
                <label class="err-tips">{{ errors[0] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">换代型号：</div>
            <div class="item-value-div">
              <validation-provider rules="max:200" v-slot="{ errors }" name="machineryModel">
                <label><input v-model="machineryInfo.model" type="text"></label>
                <label class="err-tips">{{ errors[0] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">展示图片：</div>
            <div class="preview-img-operation-div">
              <div v-for="(v, k) in machineryInfo.img" class="preview-img-div">
                <img class="del-img" src="../../assets/del.png" alt="" @click="onDelOldImg(v, k)">
                <img class="preview-img" :src="`${$imgBaseURL}/${v.url}`" alt="" @click="onShowBigImgDiv($event)">
              </div>
              <div v-for="(v, k) in machineryImgData" class="preview-img-div">
                <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg(k, false)">
                <img class="preview-img" :src="v" alt="" @click="onShowBigImgDiv($event)">
              </div>
              <div v-show="machineryImgCount() < 3">
                <label class="upload-img-label" @change="onAddImg($event)">
                  <img class="cursor-el" src="../../assets/picture.png" alt="" />
                  <input class="cursor-el" type="file" />
                </label>
              </div>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">设备部件：</div>
            <div class="item-add-div">
              <img class="cursor-el" src="../../assets/add-circle.png" alt="添加部件" @click="onAddParts"/>
            </div>
          </div>
          <div v-for="(item, index) in machineryInfo.parts" class="parts-div">
            <img class="minus-parts-img cursor-el" src="../../assets/minus-circle.png" alt="删除部件" @click="onDelParts(index, item)"/>
            <div class="item-div">
              <div class="item-name-div">排序：</div>
              <div class="item-value-div"><label><input v-model="item.index" type="text" maxlength="2"></label></div>
            </div>
            <div class="item-div">
              <div class="item-name-div">
                <label>部件模板：</label>
              </div>
              <div class="item-value-div select-div">
                <my-select class="my-select" :option-data="[{id:0, name:'请选择模板'}]" :obj="item" :load-more="true" :action="machineryPartsMsgItems" :change="onSelectPartsChanged" placeholder-txt="请选择模板"/>
                <layer>{{ item.baseName }}</layer>
              </div>
            </div>
            <div v-if="item.baseType !== 3" class="item-div">
              <div class="item-name-div">
                <label>部件备注：</label>
              </div>
              <div class="item-value-div">
                <label><textarea v-model="item.remarks" maxlength="200" /></label>
              </div>
            </div>
            <template v-if="item.baseType === 3">
              <div class="item-div">
                <div class="item-name-div">
                  <label>机型参数：</label>
                </div>
                <div class="item-add-div">
                  <img class="cursor-el" src="../../assets/add-circle.png" alt="添加机型" @click="onAddAttribute(index, false)"/>
                </div>
              </div>
              <template v-for="(attribute, i) in item.attributes">
                <div class="item-div">
                  <div class="item-name-div">机型：</div>
                  <div class="other-value-div">
                    <label><input class="model-input" maxlength="20" v-model="attribute.name" type="text"></label>
                    <img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute(index, i, false)"/>
                  </div>
                </div>
                <div class="item-div">
                  <div class="item-name-div">轴1：</div>
                  <div class="other-value-div">
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v1"></label>
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v2" placeholder="参数(50*50)"></label>
                  </div>
                </div>
                <div class="item-div">
                  <div class="item-name-div">轴2：</div>
                  <div class="other-value-div">
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v3"></label>
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v4" placeholder="参数(50*50)"></label>
                  </div>
                </div>
                <div class="item-div">
                  <div class="item-name-div">备注：</div>
                  <div class="item-value-div">
                    <label><textarea v-model="attribute.remarks" maxlength="70"></textarea></label>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div v-if="(Object.prototype.hasOwnProperty.call(item, 'specialHeader') && item.specialHeader.length) > 0" class="item-div">
                <div class="item-name-div">
                  <label>特殊参数：</label>
                </div>
                <div class="parts-value-div">
                  <div class="parts-value-item">
                    <div v-for="headerName in item.specialHeader">{{ headerName }}</div>
                    <div>行备注</div>
                    <div class="operate-btn-div"><img class="cursor-el" src="../../assets/add-circle.png" alt="增加" @click="onAddAttribute(index, true)"/></div>
                  </div>
                  <template v-for="(attribute, i) in item.specials">
                    <div class="parts-value-item">
                      <div v-for="(v, k) in attribute" v-if="k !== 'attributeId'">
                        <label>
                          <input v-model="attribute[k]" type="text" :maxlength="k === 'remarks' ? 200 : 20">
                        </label>
                      </div>
                      <div class="operate-btn-div"><img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute(index, i, true)"/></div>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="(Object.prototype.hasOwnProperty.call(item, 'attributeHeader') && item.attributeHeader.length) > 0" class="item-div">
                <div class="item-name-div">
                  <label>部件参数：</label>
                </div>
                <div class="parts-value-div">
                  <div class="parts-value-item">
                    <div v-for="headerName in item.attributeHeader">{{ headerName }}</div>
                    <div v-if="item.baseType === 2">行备注</div>
                    <div class="operate-btn-div"><img class="cursor-el" src="../../assets/add-circle.png" alt="增加" @click="onAddAttribute(index, false)"/></div>
                  </div>
                  <template v-for="(attribute, i) in item.attributes">
                    <div class="parts-value-item">
                      <div v-for="(v, k) in attribute" v-if="k !== 'attributeId'">
                        <label>
                          <input v-model="attribute[k]" type="text" :maxlength="k === 'remarks' ? 200 : 20">
                        </label>
                      </div>
                      <div class="operate-btn-div"><img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute(index, i, false)"/></div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </validation-observer>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="保存" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getMachineryDetails, getBrandNameItems, getMachineryPartsMsgItems, postMachineryEdit } from "../../common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    machineryId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      brandNameItems: getBrandNameItems,
      machineryPartsMsgItems: getMachineryPartsMsgItems,
      selectBrandNameParams: {
        pageNum: 1,
        pageSize: 11,
        type: 1
      },
      machineryInfo: {},
      delImgIndex: [],
      delPartsId: [],
      delAttributeId: [],
      machineryImg: [],
      machineryImgData: [],
      tipMsg: "",
      isShowBigImg: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getMachineryDetails({machineryId: this.machineryId})
        .then(data => {
          let imgObj = [];
          data.img.split(",").forEach((v, i) => {
            imgObj.push({index: i, url: v})
          })
          data.img = imgObj;
          data.parts.forEach(item => {
            if(item.baseType === 1 || item.baseType === 4){
              item.attributeHeader = ["名称", "属性"];
            }else if(item.baseType === 3){
              item.attributeHeader = ["标签", "名称", "属性"];
              let attributes = [];
              item.attributes.forEach((v, i) => {
                let attr = {
                  attributeId: v.attributeId,
                  name: v.name,
                  v1: "", v2: "", v3: "", v4: "", remarks: ""
                };

                if(Object.prototype.hasOwnProperty.call(v, "remarks")){
                  let temp = v.remarks.split("$");
                  if(temp.length > 0){
                    let z = temp[0].split(";")
                    if(z.length > 0){
                      let a = z[0].split(",");
                      if(a.length === 3){
                        if(a[0] === "轴1"){
                          attr.v1 = a[1];
                          attr.v2 = a[2];
                        }else{
                          attr.v3 = a[1];
                          attr.v4 = a[2];
                        }
                      }
                    }
                    if(z.length > 1){
                      let a = z[1].split(",");
                      if(a.length === 3){
                        if(a[0] === "轴1"){
                          attr.v1 = a[1];
                          attr.v2 = a[2];
                        }else{
                          attr.v3 = a[1];
                          attr.v4 = a[2];
                        }
                      }
                    }
                  }
                  if(temp.length > 1){
                    attr.remarks = temp[1];
                  }
                }

                attributes.push(attr)
              });
              item.attributes = attributes;
            }
          });
          this.machineryInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询设备信息失败，请稍后重试");
          }
          this.$layer.close(this.layerid);
        });
    },
    machineryImgCount() {
      if(Object.prototype.hasOwnProperty.call(this.machineryInfo, "img")){
        return (this.machineryInfo.img.length + this.machineryImgData.length);
      }else{
        return this.machineryImgData.length;
      }
    },
    onSelectBrandChanged(item) {
      this.machineryInfo.brandId = item.id;
    },
    onAddParts() {
      this.machineryInfo.parts.push({
        index: "99",
        baseId: "0",
        remarks: "",
        baseType: 0,
        specialHeader: [],
        attributeHeader: [],
        specials: [],
        attributes: []
      });
    },
    onDelParts(index, parts) {
      this.machineryInfo.parts.splice(index, 1);
      if(Object.prototype.hasOwnProperty.call(parts, "partsId")){
        this.delPartsId.push(parts.partsId);
      }
    },
    onSelectPartsChanged(item, obj) {
      if(Object.prototype.hasOwnProperty.call(obj, "specials")){
        obj.specials.forEach(item => {
          if(Object.prototype.hasOwnProperty.call(item, "attributeId")){
            this.delAttributeId.push(item.attributeId);
          }
        });
      }
      if(Object.prototype.hasOwnProperty.call(obj, "attributes")){
        obj.attributes.forEach(item => {
          if(Object.prototype.hasOwnProperty.call(item, "attributeId")){
            this.delAttributeId.push(item.attributeId);
          }
        });
      }

      obj.baseId = item.id;
      obj.baseType = item.type;
      obj.specials = [];
      obj.attributes = [];
      if(item.type === 1 || item.type === 4){
        obj.specialHeader = [];
        obj.attributeHeader = ["名称", "属性"];
      }else if(item.type === 2) {
        let headerMsg = item.header.split(";");
        obj.attributeHeader = headerMsg[0].split(",");
        if(headerMsg.length > 1){
          obj.specialHeader = headerMsg[1].split(",");
        }else{
          obj.specialHeader = [];
        }
      }else{
        obj.specialHeader = [];
        obj.attributeHeader = ["标签", "名称", "属性"];
      }
    },
    onAddAttribute(index, isSpecial) {
      let attributes;
      if(this.machineryInfo.parts[index].baseType === 1 || this.machineryInfo.parts[index].baseType === 4){
        attributes = {name: "", attribute1: ""};
      }else if(this.machineryInfo.parts[index].baseType === 2){
        let headerMsg = isSpecial ? this.machineryInfo.parts[index].specialHeader.length : this.machineryInfo.parts[index].attributeHeader.length
        if(headerMsg === 2){
          attributes = {name: "", attribute1: "", remarks: ""};
        }else if(headerMsg === 3){
          attributes = {name: "", attribute1: "", attribute2: "", remarks: ""};
        }else{
          attributes = {name: "", attribute1: "", attribute2: "", attribute3: "", remarks: ""};
        }
      }else{
        attributes = {name: "", v1: "", v2: "", v3: "", v4: "", remarks: ""};
      }

      if(isSpecial){
        this.machineryInfo.parts[index].specials.push(attributes);
      }else{
        this.machineryInfo.parts[index].attributes.push(attributes);
      }
    },
    onMinusAttribute(index, i, isSpecial) {
      if(isSpecial){
        if(Object.prototype.hasOwnProperty.call(this.machineryInfo.parts[index].specials[i], "attributeId")){
          this.delAttributeId.push(this.machineryInfo.parts[index].specials[i].attributeId);
        }
        this.machineryInfo.parts[index].specials.splice(i, 1);
      }else{
        if(Object.prototype.hasOwnProperty.call(this.machineryInfo.parts[index].attributes[i], "attributeId")){
          this.delAttributeId.push(this.machineryInfo.parts[index].attributes[i].attributeId);
        }
        this.machineryInfo.parts[index].attributes.splice(i, 1);
      }
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.machineryImgData.push(reader.result)
        that.machineryImg.push(img);
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelOldImg(item, index) {
      this.machineryInfo.img.splice(index, 1);
      this.delImgIndex.push(item.index);
    },
    onDelImg(index) {
      this.machineryImg.splice(index, 1);
      this.machineryImgData.splice(index, 1);
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.machineryInfo.brandId <= 0){
        this.setTipsMsg("请选择品牌");
        return;
      }
      if(this.machineryImgCount() < 1){
        this.setTipsMsg("请选择设备图片");
        return;
      }
      if(this.machineryImgCount() > 3){
        this.setTipsMsg("设备图片不能超过3张");
        return;
      }
      if(this.machineryInfo.parts.length < 1){
        this.setTipsMsg("请添加设备部件");
        return;
      }
      if(!this.validateMachineryParts()){
        return;
      }

      this.$refs.form.validate().then(res => {
        if(res){
          let formData = new FormData();
          this.delImgIndex.forEach((v, k) => {
            formData.append(`delImg[${k}]`, v);
          });
          this.machineryImg.forEach(v => {
            formData.append("file", v);
          });
          this.delPartsId.forEach((v, k) => {
            formData.append(`delParts[${k}]`, v);
          });
          this.delAttributeId.forEach((v, k) => {
            formData.append(`delAttributes[${k}]`, v);
          });

          for(let key in this.machineryInfo){
            if(key === "brandName" || key === "img"){
              continue;
            }

            if(this.machineryInfo[key].length > 0){
              if(key === "parts"){
                for(let index=0; index<this.machineryInfo.parts.length; ++index){
                  let item = this.machineryInfo.parts[index];
                  if(Object.prototype.hasOwnProperty.call(item, "partsId")){
                    formData.append(`parts[${index}].partsId`, item.partsId);
                  }
                  formData.append(`parts[${index}].index`, item.index);
                  formData.append(`parts[${index}].baseId`, item.baseId);

                  if(item.baseType === 3){
                    for(let i=0; i<item.attributes.length; ++i){
                      let v = item.attributes[i];
                      if((v.v1.length > 0 || v.v2.length > 0) && (v.v1.length < 1 || v.v2.length < 1)){
                        this.setTipsMsg(`请在部件${index+1}中，完善轴1参数`);
                        return;
                      }
                      if((v.v3.length > 0 || v.v4.length > 0) && (v.v3.length < 1 || v.v4.length < 1)){
                        this.setTipsMsg(`请在部件${index+1}中，完善轴2参数`);
                        return;
                      }
                      if(v.name.search(/[,;$]/) >= 0 || v.v1.search(/[,;$]/) >= 0 || v.v2.search(/[,;$]/) >= 0
                          || v.v3.search(/[,;$]/) >= 0 || v.v4.search(/[,;$]/) >= 0
                          || v.remarks.search(/[,;$]/) >= 0){
                        this.setTipsMsg(`请在部件${index+1}中，不能含有,;$特殊符号`);
                        return;
                      }

                      let temp = "";
                      if(v.v1.length > 0 && v.v2.length > 0){
                        temp += `轴1,${v.v1},${v.v2}`;
                      }
                      if(v.v3.length > 0 && v.v4.length > 0){
                        if(temp.length > 0){
                          temp += ";";
                        }
                        temp += `轴2,${v.v3},${v.v4}`;
                      }
                      if(temp.length < 1){
                        this.setTipsMsg(`请在部件${index+1}中，完善参数`);
                        return;
                      }

                      let remarks = `${temp}$${v.remarks}`;
                      formData.append(`parts[${index}].attributes[${i}].name`, v.name);
                      formData.append(`parts[${index}].attributes[${i}].remarks`, remarks);
                      if(Object.prototype.hasOwnProperty.call(v, "attributeId")){
                        formData.append(`parts[${index}].attributes[${i}].attributeId`, v.attributeId);
                      }
                    }
                  }else{
                    if(Object.prototype.hasOwnProperty.call(item, "remarks") && item.remarks.length > 0){
                      formData.append(`parts[${index}].remarks`, item.remarks);
                    }

                    if(Object.prototype.hasOwnProperty.call(item, "specials") && item.specials.length > 0){
                      item.specials.forEach((v, i) => {
                        for(let keyName in v){
                          if(v.hasOwnProperty(keyName) && v[keyName].length > 0){
                            formData.append(`parts[${index}].specials[${i}].${keyName}`, v[keyName]);
                          }
                        }
                      });
                    }
                    if(Object.prototype.hasOwnProperty.call(item, "attributes") && item.attributes.length > 0){
                      item.attributes.forEach((v, i) => {
                        for(let keyName in v){
                          if(v.hasOwnProperty(keyName) && v[keyName].length > 0){
                            formData.append(`parts[${index}].attributes[${i}].${keyName}`, v[keyName]);
                          }
                        }
                      });
                    }
                  }
                }
              }else{
                formData.append(key, this.machineryInfo[key]);
              }
            }
          }

          postMachineryEdit(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    validateMachineryParts() {
      let msg;
      let validateResult = this.machineryInfo.parts.every((item, k) => {
        if(item.baseId <= 0){
          msg = `请在第${k+1}个部件，选择部件模板`;
          return false;
        }

        if(Object.prototype.hasOwnProperty.call(item, "index")){
          if(!(/^\d{1,2}?$/.test(item.index))){
            msg = `第${k+1}个部件，位置下标信息错误，位置下标只能在0~99范围内`;
          }
        }else{
          msg = `请在第${k+1}个部件，填写位置下标`;
        }

        if(Object.prototype.hasOwnProperty.call(item, "remarks") && item.remarks.length > 200){
          msg = `请修改第${k+1}个部件备注， 备注信息不能超过200个字符`;
          return false;
        }

        if(item.specials.length < 1 && item.attributes.length < 1){
          msg = `请在第${k+1}个部件，完善参数信息`;
          return false;
        }

        if(item.specials.length > 0){
          let result = item.specials.every(v => {
            if(v.name.length < 1){
              msg = `请在第${k+1}个部件，完善参数参数名称`;
              return false;
            }else{
              return true;
            }
          });
          if(!result){
            return false;
          }
        }

        if(item.attributes.length > 0){
          let result = item.attributes.every(v => {
            if(v.name.length < 1){
              msg = `请在第${k+1}个部件，完善参数参数名称`;
              return false;
            }else{
              return true;
            }
          });
          if(!result){
            return false;
          }
        }
        return true;
      });

      if(!validateResult){
        this.setTipsMsg(msg);
      }
      return validateResult;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .add-parts-div {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    display: flex;
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
  }
  .layer-content-div {
    width: 95%;
    margin: 10px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 90px;
        text-align: right;
      }
      .item-add-div {
        display: flex;
        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
        }
      }
      .item-value-div {
        flex: 1;
        input {
          width: 288px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 292px;
          height: 80px;
          resize: none;
          padding: 0 3px;
          border: 1px solid #dcdcdc;
        }
      }
      .select-div {
        display: flex;
        align-items: center;
        .my-select {
          width: 300px;
          height: 33px;
          margin-right: 5px;
        }
      }
      .parts-value-div {
        flex: 1;
        .parts-value-item:first-child {
          div {
            background-color: #cccccc;
          }
          .operate-btn-div {
            background-color: transparent;
          }
        }
        .parts-value-item {
          flex: 1;
          display: flex;
          div {
            width: 90px;
            line-height: 25px;
            text-align: center;
            margin-right: 5px;
            input {
              width: 80px;
              height: 20px;
              padding: 0 2px;
              border: 1px solid #dcdcdc;
            }
          }
          div:nth-last-child(2) {
            flex: 1;
            input {
              width: calc(100% - 10px);
            }
          }
          .operate-btn-div {
            width: 20px;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              vertical-align: middle;
            }
          }
        }
      }
      .other-value-div {
        .model-input {
          width: 199px;
          height: 23px;
          padding: 0 2px;
          border: 1px solid #dcdcdc;
        }
        .param-input {
          width: 142px;
          height: 23px;
          padding: 0 2px;
          border: 1px solid #dcdcdc;
          margin-right: 5px;
        }
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
    .parts-div {
      width: 80%;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      padding: 10px;
      position: relative;
      margin: 10px auto 0 auto;
      .item-div:first-child {
        margin-top: 0;
      }
      .minus-parts-img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
    .preview-img-operation-div {
      display: flex;
      .preview-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-right: 10px;
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
        .preview-img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
        }
      }
    }
    .err-tips {
      margin-left: 5px;
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>