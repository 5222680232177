<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="add-parts-div">
      <img class="cursor-el" src="../../assets/add-circle.png" alt="添加部件" title="添加部件" @click="onAddParts"/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div>
          <div class="item-div">
            <div class="item-name-div">选择品牌：</div>
            <div class="item-value-div">
              <my-select class="mySelect" :option-data="[{id:0, name:'请选择品牌'}]" :load-more="true" :action="brandNameItems" :params="selectBrandNameParams" :change="onSelectBrandChanged" placeholder-txt="请选择品牌"/>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">设备名称：</div>
            <div class="item-value-div">
              <validation-provider rules="required|minmax:1,20" v-slot="{ errors }" name="machineryName">
                <label><input v-model="machineryMsg.name" type="text"></label>
                <label class="err-tips">{{ errors[0] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">斗容量：</div>
            <div class="item-value-div">
              <validation-provider rules="max:200" v-slot="{ errors }" name="subtitle">
                <label><input v-model="machineryMsg.subtitle" type="text"></label>
                <label class="err-tips">{{ errors[0] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">换代型号：</div>
            <div class="item-value-div">
              <validation-provider rules="max:200" v-slot="{ errors }" name="machineryModel">
                <label><input v-model="machineryMsg.model" type="text"></label>
                <label class="err-tips">{{ errors[0] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">展示图片：</div>
            <div class="preview-img-operation-div">
              <div v-for="(v, k) in machineryImgData" class="preview-img-div">
                <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg(k)">
                <img class="preview-img" :src="v" alt="" @click="onShowBigImgDiv($event)">
              </div>
              <div v-show="machineryImgData.length < 3">
                <label class="upload-img-label" @change="onAddImg($event)">
                  <img class="cursor-el" src="../../assets/picture.png" alt="" />
                  <input class="cursor-el" type="file" />
                </label>
              </div>
            </div>
          </div>
          <div class="item-div">
            <div class="item-name-div">
              <label>设备部件：</label>
            </div>
            <div class="item-add-div">
              <img class="cursor-el" src="../../assets/add-circle.png" alt="添加部件" @click="onAddParts"/>
            </div>
          </div>
          <div v-for="(item, index) in machineryMsg.parts" class="parts-div">
            <img class="minus-parts-img cursor-el" src="../../assets/minus-circle.png" alt="删除部件" @click="onDelParts(index)"/>
            <div class="item-div">
              <div class="item-name-div">
                <label>部件模板：</label>
              </div>
              <div>
                <my-select class="mySelect" :option-data="[{id:0, name:'请选择模板'}]" :obj="item" :load-more="true" :action="machineryPartsMsgItems" :change="onSelectPartsChanged" placeholder-txt="请选择模板"/>
              </div>
            </div>
            <div v-if="item.type !== 3" class="item-div">
              <div class="item-name-div">
                <label>部件备注：</label>
              </div>
              <div class="item-value-div">
                <validation-provider rules="max:200" v-slot="{ errors }" name="remarks">
                  <label><textarea v-model="item.remarks" maxlength="200"></textarea></label>
                  <label class="err-tips">{{ errors[0] }}</label>
                </validation-provider>
              </div>
            </div>
            <template v-if="item.type === 3">
              <div class="item-div">
                <div class="item-name-div">
                  <label>机型参数：</label>
                </div>
                <div class="item-add-div">
                  <img class="cursor-el" src="../../assets/add-circle.png" alt="添加机型" @click="onAddAttribute(index, false)"/>
                </div>
              </div>
              <template v-for="(attribute, i) in item.attributes">
                <div class="item-div">
                  <div class="item-name-div">机型：</div>
                  <div class="other-value-div">
                    <label><input class="model-input" maxlength="20" v-model="attribute.name" type="text"></label>
                    <img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute(index, i, false)"/>
                  </div>
                </div>
                <div class="item-div">
                  <div class="item-name-div">轴1：</div>
                  <div class="other-value-div">
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v1"></label>
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v2" placeholder="参数(50*50)"></label>
                  </div>
                </div>
                <div class="item-div">
                  <div class="item-name-div">轴2：</div>
                  <div class="other-value-div">
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v3"></label>
                    <label><input class="param-input" type="text" maxlength="20" v-model="attribute.v4" placeholder="参数(50*50)"></label>
                  </div>
                </div>
                <div class="item-div">
                  <div class="item-name-div">备注：</div>
                  <div class="item-value-div">
                    <label><textarea v-model="attribute.remarks" maxlength="70"></textarea></label>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div v-if="item.specialHeader.length > 0" class="item-div">
                <div class="item-name-div">
                  <label>特殊参数：</label>
                </div>
                <div class="parts-value-div">
                  <div class="parts-value-item">
                    <div v-for="headerName in item.specialHeader">{{ headerName }}</div>
                    <div>行备注</div>
                    <div class="operate-btn-div"><img class="cursor-el" src="../../assets/add-circle.png" alt="增加" @click="onAddAttribute(index, true)"/></div>
                  </div>
                  <template v-for="(attribute, i) in item.specials">
                    <div class="parts-value-item">
                      <div v-for="(v, k) in attribute">
                        <label>
                          <input v-model="attribute[k]" type="text" :maxlength="k === 'remarks' ? 200 : 20">
                        </label>
                      </div>
                      <div class="operate-btn-div"><img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute(index, i, true)"/></div>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="item.attributeHeader.length > 0" class="item-div">
                <div class="item-name-div">
                  <label>部件参数：</label>
                </div>
                <div class="parts-value-div">
                  <div class="parts-value-item">
                    <div v-for="headerName in item.attributeHeader">{{ headerName }}</div>
                    <div v-if="item.type === 2">行备注</div>
                    <div class="operate-btn-div"><img class="cursor-el" src="../../assets/add-circle.png" alt="增加" @click="onAddAttribute(index, false)"/></div>
                  </div>
                  <template v-for="(attribute, i) in item.attributes">
                    <div class="parts-value-item">
                      <div v-for="(v, k) in attribute">
                        <label>
                          <input v-model="attribute[k]" type="text" :maxlength="k === 'remarks' ? 200 : 20">
                        </label>
                      </div>
                      <div class="operate-btn-div"><img class="cursor-el" src="../../assets/minus-circle.png" alt="删除" @click="onMinusAttribute(index, i, false)"/></div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </validation-observer>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="保存" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getBrandNameItems, getMachineryPartsMsgItems, postMachineryAdd } from "../../common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      brandNameItems: getBrandNameItems,
      machineryPartsMsgItems: getMachineryPartsMsgItems,
      selectBrandNameParams: {
        pageNum: 1,
        pageSize: 11,
        type: 1
      },
      machineryMsg: {
        brandId: "",
        name: "",
        subtitle: "",
        model: "",
        parts: []
      },
      machineryImg: [],
      machineryImgData: [],
      tipMsg: "",
      isShowBigImg: false
    }
  },
  methods: {
    onSelectBrandChanged(item) {
      this.machineryMsg.brandId = item.id;
    },
    onAddParts() {
      this.machineryMsg.parts.push({
        baseId: "0",
        remarks: "",
        type: 0,
        specialHeader: [],
        attributeHeader: [],
        specials: [],
        attributes: []
      });
    },
    onDelParts(index) {
      this.machineryMsg.parts.splice(index, 1);
    },
    onSelectPartsChanged(item, obj) {
      obj.baseId = item.id;
      obj.type = item.type;
      obj.specials = [];
      obj.attributes = [];
      if(item.type === 1 || item.type === 4){
        obj.specialHeader = [];
        obj.attributeHeader = ["名称", "属性"];
      }else if(item.type === 2) {
        let headerMsg = item.header.split(";");
        obj.attributeHeader = headerMsg[0].split(",");
        if(headerMsg.length > 1){
          obj.specialHeader = headerMsg[1].split(",");
        }else{
          obj.specialHeader = [];
        }
      }else{
        obj.specialHeader = [];
        obj.attributeHeader = ["标签", "名称", "属性"];
      }
    },
    onAddAttribute(index, isSpecial) {
      let attributes;
      if(this.machineryMsg.parts[index].type === 1 || this.machineryMsg.parts[index].type === 4){
        attributes = {name: "", attribute1: ""};
      }else if(this.machineryMsg.parts[index].type === 2){
        let headerMsg = isSpecial ? this.machineryMsg.parts[index].specialHeader.length : this.machineryMsg.parts[index].attributeHeader.length
        if(headerMsg === 2){
          attributes = {name: "", attribute1: "", remarks: ""};
        }else if(headerMsg === 3){
          attributes = {name: "", attribute1: "", attribute2: "", remarks: ""};
        }else{
          attributes = {name: "", attribute1: "", attribute2: "", attribute3: "", remarks: ""};
        }
      }else{
        attributes = {name: "", v1: "", v2: "", v3: "", v4: "", remarks: ""};
      }

      if(isSpecial){
        this.machineryMsg.parts[index].specials.push(attributes);
      }else{
        this.machineryMsg.parts[index].attributes.push(attributes);
      }
    },
    onMinusAttribute(index, i, isSpecial) {
      if(isSpecial){
        this.machineryMsg.parts[index].specials.splice(i, 1);
      }else{
        this.machineryMsg.parts[index].attributes.splice(i, 1);
      }
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.machineryImgData.push(reader.result)
        that.machineryImg.push(img);
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelImg(index) {
      this.machineryImg.splice(index, 1);
      this.machineryImgData.splice(index, 1);
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.machineryMsg.brandId <= 0){
        this.setTipsMsg("请选择品牌");
        return;
      }
      if(this.machineryImg.length < 1){
        this.setTipsMsg("请选择设备图片");
        return;
      }
      if(this.machineryImg.length > 3){
        this.setTipsMsg("设备图片不能超过3张");
        return;
      }
      if(this.machineryMsg.parts.length < 1){
        this.setTipsMsg("请添加设备部件");
        return;
      }
      if(!this.validateMachineryParts()){
        return;
      }

      this.$refs.form.validate().then(res => {
        if(res){
          let formData = new FormData();
          for(let key in this.machineryMsg){
            if(this.machineryMsg[key].length > 0){
              if(key === "parts"){
                for(let index=0; index<this.machineryMsg.parts.length; ++index){
                  let item = this.machineryMsg.parts[index];
                  formData.append(`parts[${index}].baseId`, item.baseId);

                  if(item.type === 3){
                    for(let i=0; i<item.attributes.length; ++i){
                      let v = item.attributes[i];
                      if((v.v1.length > 0 || v.v2.length > 0) && (v.v1.length < 1 || v.v2.length < 1)){
                        this.setTipsMsg(`请在部件${index+1}中，完善轴1参数`);
                        return;
                      }
                      if((v.v3.length > 0 || v.v4.length > 0) && (v.v3.length < 1 || v.v4.length < 1)){
                        this.setTipsMsg(`请在部件${index+1}中，完善轴2参数`);
                        return;
                      }
                      if(v.name.search(/[,;$]/) >= 0 || v.v1.search(/[,;$]/) >= 0 || v.v2.search(/[,;$]/) >= 0
                          || v.v3.search(/[,;$]/) >= 0 || v.v4.search(/[,;$]/) >= 0
                          || v.remarks.search(/[,;$]/) >= 0){
                        this.setTipsMsg(`请在部件${index+1}中，不能含有,;$特殊符号`);
                        return;
                      }

                      let temp = "";
                      if(v.v1.length > 0 && v.v2.length > 0){
                        temp += `轴1,${v.v1},${v.v2}`;
                      }
                      if(v.v3.length > 0 && v.v4.length > 0){
                        if(temp.length > 0){
                          temp += ";";
                        }
                        temp += `轴2,${v.v3},${v.v4}`;
                      }
                      if(temp.length < 1){
                        this.setTipsMsg(`请在部件${index+1}中，完善参数`);
                        return;
                      }

                      let remarks = `${temp}$${v.remarks}`;
                      formData.append(`parts[${index}].attributes[${i}].name`, v.name);
                      formData.append(`parts[${index}].attributes[${i}].remarks`, remarks);
                    }
                  }else{
                    if(item.remarks.length > 0){
                      formData.append(`parts[${index}].remarks`, item.remarks);
                    }
                    if(item.specials.length > 0){
                      item.specials.forEach((v, i) => {
                        for(let keyName in v){
                          if(v.hasOwnProperty(keyName) && v[keyName].length > 0){
                            formData.append(`parts[${index}].specials[${i}].${keyName}`, v[keyName]);
                          }
                        }
                      });
                    }
                    if(item.attributes.length > 0){
                      item.attributes.forEach((v, i) => {
                        for(let keyName in v){
                          if(v.hasOwnProperty(keyName) && v[keyName].length > 0){
                            formData.append(`parts[${index}].attributes[${i}].${keyName}`, v[keyName]);
                          }
                        }
                      });
                    }
                  }
                }
              }else{
                formData.append(key, this.machineryMsg[key]);
              }
            }
          }
          this.machineryImg.forEach(v => {
            formData.append("file", v);
          })

          postMachineryAdd(formData)
            .then(() => {
              this.refreshData();
              // this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    validateMachineryParts() {
      let msg;
      let validateResult = this.machineryMsg.parts.every((item, k) => {
        if(item.baseId <= 0){
          msg = `请在第${k+1}个部件，选择部件模板`;
          return false;
        }

        if(item.specials.length < 1 && item.attributes.length < 1){
          msg = `请在第${k+1}个部件，完善参数信息`;
          return false;
        }

        if(item.specials.length > 0){
          let result = item.specials.every(v => {
            if(v.name.length < 1){
              msg = `请在第${k+1}个部件，完善参数参数名称`;
              return false;
            }else{
              return true;
            }
          });
          if(!result){
            return false;
          }
        }

        if(item.attributes.length > 0){
          let result = item.attributes.every(v => {
            if(v.name.length < 1){
              msg = `请在第${k+1}个部件，完善参数参数名称`;
              return false;
            }else{
              return true;
            }
          });
          if(!result){
            return false;
          }
        }
        return true;
      });

      if(!validateResult){
        this.setTipsMsg(msg);
      }
      return validateResult;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .add-parts-div {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    display: flex;
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
  }
  .layer-content-div {
    width: 95%;
    margin: 10px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 90px;
        text-align: right;
      }
      .item-add-div {
        display: flex;
        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
        }
      }
      .item-value-div {
        input {
          width: 288px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 292px;
          height: 80px;
          resize: none;
          padding: 0 3px;
          border: 1px solid #dcdcdc;
        }
      }
      .parts-value-div {
        .parts-value-item:first-child {
          div {
            background-color: #cccccc;
          }
          .operate-btn-div {
            background-color: transparent;
          }
        }
        .parts-value-item {
          display: flex;
          div {
            width: 90px;
            line-height: 25px;
            text-align: center;
            margin-right: 5px;
            input {
              width: 80px;
              height: 20px;
              padding: 0 2px;
              border: 1px solid #dcdcdc;
            }
          }
          .operate-btn-div {
            width: 20px;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              vertical-align: middle;
            }
          }
        }
      }
      .mySelect {
        width: 300px;
        height: 33px;
      }
      .other-value-div {
        .model-input {
          width: 199px;
          height: 23px;
          padding: 0 2px;
          border: 1px solid #dcdcdc;
        }
        .param-input {
          width: 142px;
          height: 23px;
          padding: 0 2px;
          border: 1px solid #dcdcdc;
          margin-right: 5px;
        }
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
    .parts-div {
      width: 80%;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      padding: 10px;
      position: relative;
      margin: 10px auto 0 auto;
      .item-div:first-child {
        margin-top: 0;
      }
      .minus-parts-img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
    .preview-img-operation-div {
      display: flex;
      .preview-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-right: 10px;
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
        .preview-img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
        }
      }
    }
    .err-tips {
      margin-left: 5px;
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>